import { AskResponse, Citation } from "../../api";
import { cloneDeep } from "lodash-es";

const LENGTH_DOC_N = 5; //Length of string "[doc_"

type ParsedAnswer = {
    citations: Citation[];
    markdownFormatText: string;
};

const enumerateCitations = (citations: Citation[]) => {
    const filepathMap = new Map();
    for (const citation of citations) {
        const { filepath } = citation;
        let part_i = 1
        if (filepathMap.has(filepath)) {
            part_i = filepathMap.get(filepath) + 1;
        }
        filepathMap.set(filepath, part_i);
        citation.part_index = part_i;
    }
    return citations;
}

export function parseAnswer({ answer, citations }: AskResponse): ParsedAnswer {
    let answerText = answer;
    const citationLinks = answer.match(/\[(doc_\d\d?\d?)]/g);

    let filteredCitations = [] as Citation[];
    let citationReindex = 0;

    if (!citationLinks) {
        return {
            citations: [],
            markdownFormatText: answerText
        };
    }

    for (const link of citationLinks) {
        // Replacing the links/citations with number
        let citationIndex = link.slice(LENGTH_DOC_N, link.length - 1);
        let citation = cloneDeep(citations[Number(citationIndex) - 1]) as Citation;

        if (!filteredCitations.find((c) => c.id === citationIndex) && citation) {
            answerText = answerText.replaceAll(link, ` ^${++citationReindex}^ `);
            citation.id = citationIndex; // original doc index to de-dupe
            citation.reindex_id = citationReindex.toString(); // reindex from 1 for display
            filteredCitations.push(citation);
        }
    }

    filteredCitations = enumerateCitations(filteredCitations);

    return {
        citations: filteredCitations,
        markdownFormatText: answerText
    };
}
