import { Topic } from "../../models/topic";
import { useChatStore } from "../../store/Chat.store";

export const handleTopicSelection = (topicSelected: Topic) => {
    useChatStore.setState({ topicSelected, userPrompt: topicSelected.userPrompt});
    const clearPrompts = document.getElementById('clearPrompts');
    const textarea = document.getElementById('promptTextArea') as HTMLTextAreaElement | null;
    if (clearPrompts) {
        clearPrompts.style.display = 'block';
        // Add click event listener to clear the textarea
        clearPrompts.onclick = () => {
            if (textarea) {
                // Clear the textarea content
                textarea.value = '';
                // Update the state to reflect the empty prompt
                useChatStore.setState({ userPrompt: "" });

                // Hide the clearPrompts div after clearing the textarea
                clearPrompts.style.display = 'none';
            }
        };
    }
    setTimeout(() => {
        const scrollDiv = document.getElementById('scrollDiv');
        if (scrollDiv) {
            scrollDiv.scrollTop = scrollDiv.scrollHeight;
        }
    }, 250);
    
}
