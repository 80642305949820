import { useState, useEffect, useRef, useContext } from "react";
import { IconButton, Stack } from "@fluentui/react";
import Send from "../../assets/Send.svg";
import styles from "./QuestionInput.module.css";
import { FileUploadModal } from './FileUploadModal';
import { ChatState, useChatStore } from "../../store/Chat.store";
import React from 'react';
import { AppStateContext } from "../../state/AppProvider";

interface Props {
    onSend: (question: string, id?: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    conversationId?: string;
}

export const QuestionInput = ({ onSend, placeholder, clearOnSend, conversationId }: Props) => {
    const appStateContext = useContext(AppStateContext);
    const [displayUploadModal, setDisplayUploadModal] = useState(false);
    const userPrompt = useChatStore((state: ChatState) => state.userPrompt);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const clearPromptsRef = useRef<HTMLDivElement>(null);
    const tenantSettings = appStateContext?.state.tenantSettings;

    const adjustHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        adjustHeight(); // Adjust height on mount and when userPrompt changes
    }, [userPrompt]);

    const updateUserPrompt = (newUserPrompt: string) => {
        useChatStore.setState({ userPrompt: newUserPrompt });
        const clearPrompts = document.getElementById('clearPrompts');
        const textarea = document.getElementById('promptTextArea') as HTMLTextAreaElement | null;

        // Check if the viewport width is less than or equal to 480px
        if (window.innerWidth <= 480 && clearPrompts) {
            if (newUserPrompt.trim() === "") {
                // Hide clearPrompts if the text is empty
                clearPrompts.style.display = 'none';
            } else {
                // Show clearPrompts if there's text in the textarea
                clearPrompts.style.display = 'block';
            }

            // Add click event listener to clear the textarea
            clearPrompts.onclick = () => {
                if (textarea) {
                    // Clear the textarea content
                    textarea.value = '';
                    // Update the state to reflect the empty prompt
                    useChatStore.setState({ userPrompt: "" });

                    // Hide the clearPrompts div after clearing the textarea
                    clearPrompts.style.display = 'none';
                }
            };
        }
    };

    const sendQuestion = () => {
        if (!userPrompt.trim()) {
            return;
        }

        // Track the event using the Fathom global object
        if (window.fathom) {
            window.fathom.trackEvent('research_query_submitted', { eventData: { source: 'send_button' } });
            console.log('tracked');
        }

        if (conversationId) {
            onSend(userPrompt, conversationId);
        } else {
            onSend(userPrompt);
        }

        if (clearOnSend) {
            useChatStore.setState({ userPrompt: "" });
        }

        const openPromptButton = document.getElementById('openDialogButtonTopic');
        openPromptButton?.style.setProperty('display', 'block');
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey && !(ev.nativeEvent?.isComposing === true)) {
            ev.preventDefault();

            sendQuestion();
        }
    };


    return (
        <Stack horizontal className={styles.questionInputContainer}>
            {
                tenantSettings?.visibility.fileUploader && <>
                    <IconButton
                        iconProps={{ iconName: 'Attach', className: styles.whiteIcon }}
                        id="uploadButton"
                        styles={{
                            icon: {
                                fontSize: 24
                            },
                            root: {
                                backgroundColor: '#006D9B',
                                height: '50px',
                                width: '50px',
                                marginLeft: '10px',
                                marginTop: '10px',
                                borderRadius: '8px'
                            },
                            rootHovered: { backgroundColor: '#0eb6e3' },
                            rootPressed: { backgroundColor: '#0eb6e3' }
                        }}
                        ariaLabel="Upload files"
                        onClick={() => setDisplayUploadModal(true)}
                    />

                    <FileUploadModal isOpen={displayUploadModal} setOpen={(val) => setDisplayUploadModal(val)} />
                </>
            }

            <div className={styles.textField}>
                <div className={styles.textFieldWrapper}>
                    <div className={styles.textFieldGroup} id="textFieldGroup">
                        <textarea
                            id="promptTextArea"
                            className={styles.textFieldArea}
                            placeholder="Message Madison AI"
                            aria-invalid="false"
                            value={userPrompt}
                            ref={textareaRef}
                            onChange={(e) => updateUserPrompt(e.target.value)}
                            rows={1}
                            onKeyDown={onEnterPress}
                            style={{ resize: 'none', overflow: 'hidden' }}
                        ></textarea>
                        <div
                            className={styles.clearPrompts}
                            id="clearPrompts"
                            ref={clearPromptsRef}
                            style={{ display: 'none', position: 'absolute' }}
                        ></div>
                    </div>
                </div>
            </div>

            <div
                id="promptEnter"
                className={styles.questionInputSendButtonContainer}
                role="button"
                tabIndex={0}
                aria-label="Ask question button"
                onClick={sendQuestion}
                onKeyDown={e => e.key === "Enter" || e.key === " " ? sendQuestion() : null}
            >
                <img src={Send} className={styles.questionInputSendButton} />
            </div>

        </Stack>
    );
};
