import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Dialog, Stack, TextField } from "@fluentui/react";
import { CopyRegular } from "@fluentui/react-icons";
import styles from "./Layout.module.css";
import { HistoryButton } from "../../components/common/Button";
import { AppStateContext } from "../../state/AppProvider";
import { CosmosDBStatus } from "../../api";
import { NavContainer } from "./Content";

const Layout = () => {
    const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false);
    const [copyClicked, setCopyClicked] = useState<boolean>(false);
    const [copyText, setCopyText] = useState<string>("Copy URL");
    const [shareLabel, setShareLabel] = useState<string | undefined>("Share");
    const [hideHistoryLabel, setHideHistoryLabel] = useState<string>("Hide chat history");
    const [showHistoryLabel, setShowHistoryLabel] = useState<string>("Show chat history");
    const appStateContext = useContext(AppStateContext);
    const ui = appStateContext?.state.frontendSettings?.ui;
    const tenantSettings = appStateContext?.state.tenantSettings;

    const handleShareClick = () => {
        setIsSharePanelOpen(true);
    };

    const refreshPage = (): void => {
        window.location.reload();
    };

    const handleSharePanelDismiss = () => {
        setIsSharePanelOpen(false);
        setCopyClicked(false);
        setCopyText("Copy URL");
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopyClicked(true);
    };

    const handleHistoryClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' });
        const historyButton = document.getElementById('historyButton');
        const resetPage = document.getElementById('resetPage');
        const headerSelectNav = document.getElementById('headerSelectNavFixed');

        if (historyButton) {
            const currentLeft = historyButton.style.left;
            historyButton.style.left = currentLeft === '320px' ? '20px' : '320px';
        }
        if (resetPage) {
            const currentLeft = resetPage.style.left;
            resetPage.style.left = currentLeft === '360px' ? '60px' : '360px';
        }
        if (headerSelectNav) {
            const currentLeft = headerSelectNav.style.paddingLeft;
            headerSelectNav.style.paddingLeft = currentLeft === '412px' ? '112px' : '412px';
        }
    };

    useEffect(() => {
        if (copyClicked) {
            setCopyText("Copied URL");
        }
    }, [copyClicked]);

    useEffect(() => { }, [appStateContext?.state.isCosmosDBAvailable?.status]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 480) {
                setShareLabel(undefined);
                setHideHistoryLabel("Hide history");
                setShowHistoryLabel("Show history");
            } else {
                setShareLabel("Share");
                setHideHistoryLabel("Hide chat history");
                setShowHistoryLabel("Show chat history");
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return <>
        {
            tenantSettings && <div className={styles.layout}>
                <Stack horizontal tokens={{ childrenGap: 4 }}>
                    {appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured && tenantSettings?.visibility.history && (
                        <>
                            <HistoryButton
                                data-tip="Toggle chat history"
                                onClick={handleHistoryClick}
                                text={
                                    appStateContext?.state?.isChatHistoryOpen
                                        ? hideHistoryLabel
                                        : showHistoryLabel
                                }
                                title="Open Chat History"
                            />
                            <ReactTooltip id="chat-tooltip" className="tool__tips" />
                        </>
                    )}
                </Stack>
                <>
                    <a data-tooltip-id="reset-tooltip" data-tooltip-html="Start a new chat" className={styles.resetPage} id="resetPage" href="/">
                        <img src="https://54493.fs1.hubspotusercontent-na1.net/hubfs/54493/iGovern/icons/icon-reset-light.svg" alt="Reset Icon" />
                    </a>
                    <ReactTooltip id="reset-tooltip" className="tool__tips" />
                </>

                <header>
                    <NavContainer tenantSettings={tenantSettings} />
                </header>

                <Outlet />

                <Dialog>
                    <Stack horizontal verticalAlign="center" style={{ gap: "8px" }}>
                        <TextField
                            className={styles.urlTextBox}
                            defaultValue={window.location.href}
                            readOnly
                        />
                        <div
                            className={styles.copyButtonContainer}
                            role="button"
                            tabIndex={0}
                            aria-label="Copy"
                            onClick={handleCopyClick}
                            onKeyDown={(e) =>
                                e.key === "Enter" || e.key === " " ? handleCopyClick() : null
                            }
                        >
                            <CopyRegular className={styles.copyButton} />
                            <span className={styles.copyButtonText}>{copyText}</span>
                        </div>
                    </Stack>
                </Dialog>

                {tenantSettings?.visibility.footer && (
                    <footer className={styles.footer}>
                        <div className={styles.footer__container}>
                            <div className={styles.footer__logo}>
                                <img src="https://54493.fs1.hubspotusercontent-na1.net/hubfs/54493/iGovern/icons/MadisonLogovWhite.svg" alt="Madison Logo" />
                                <span className={styles.footer__copyright}>
                                    © Madison AI 2024. All rights reserved.
                                </span>
                            </div>
                            <div className={styles.footer__sectionLinks}>
                                {
                                    tenantSettings.footer.map((footerItem, index) => (
                                        <>
                                            <a key={footerItem.id} target="_blank" href={footerItem.url} className={styles.footer__sectionLink}>
                                                {footerItem.title}
                                            </a>
                                            <span className={styles.footer__sectionLinkDivider}></span>
                                        </>
                                    ))
                                }
                                <a target="_blank" href="https://madisonai.com/" className={styles.footer__sectionLink}>
                                    About Madison AI
                                </a>

                                <span className={styles.footer__sectionLinkDivider}></span>

                                <a href="mailto:?subject=MadisonAI&body=https://madisonai.com" className={styles.footer__sectionLink}>
                                    Share With a Friend
                                </a>
                            </div>
                            <div className={styles.footer__actionBtns}>
                                <a target="_blank" href="https://madisonai.com/contact/" className={styles.footer__sectionLink}>
                                    <button
                                        className={`${styles.footer__btn} ${styles.footer__btnPrimary}`}
                                    >
                                        Get Help
                                    </button>
                                </a>
                            </div>
                        </div>
                    </footer>
                )}
            </div>
        }
    </>
};

export default Layout;