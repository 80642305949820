// LoadingSequence.tsx
import React, { useState, useEffect, useRef } from 'react';
import styles from './LoadingSequence.module.css';

const LoadingSequence: React.FC = () => {
    const [currentPhase, setCurrentPhase] = useState<string>('Searching...');
    const [documentNumber, setDocumentNumber] = useState<number>(1);
    const [isCountingDocuments, setIsCountingDocuments] = useState<boolean>(false);
    const [dotCount, setDotCount] = useState<number>(1); // For cycling the ellipses

    const currentTimeRef = useRef<number>(0);

    // Cycle dotCount (1 to 3) every 500ms for animated ellipses
    useEffect(() => {
        const dotInterval = setInterval(() => {
            setDotCount(prev => (prev % 3) + 1);
        }, 500);
        return () => clearInterval(dotInterval);
    }, []);

    useEffect(() => {
        let docIntervalId: number | null = null;
        if (isCountingDocuments) {
            // Counting at 5 ms per increment
            // 5ms * 100 = 500ms total to reach 100, very fast
            docIntervalId = window.setInterval(() => {
                setDocumentNumber(prev => {
                    if (prev < 100) {
                        return prev + 1;
                    } else {
                        if (docIntervalId) clearInterval(docIntervalId);
                        return 100;
                    }
                });
            }, 3);
        }

        return () => {
            if (docIntervalId) clearInterval(docIntervalId);
        };
    }, [isCountingDocuments]);

    useEffect(() => {
        let mainIntervalId: number;

        const updateSequence = () => {
            const t = currentTimeRef.current;

            // Adjusted Timeline:
            // 0–1000 ms: Searching...
            // 1000–1500 ms: Retrieving documents...
            // 1500–7500 ms: Retrieving document 1–100 (6s)
            // 7500–8000 ms: Processing documents...
            // 8000–15000 ms: Processing document 1–100 (7s)
            // 15000–17000 ms: Thinking... (2s)
            // >17000 ms: Generating results...

            if (t < 1000) {
                setCurrentPhase('Searching...');
                setIsCountingDocuments(false);
            } else if (t < 1500) {
                setCurrentPhase('Retrieving documents...');
                setIsCountingDocuments(false);
            } else if (t < 7500) {
                // Retrieving counting phase
                if (t >= 1500 && t < 1600) {
                    setDocumentNumber(1);
                }
                setCurrentPhase(`Retrieving document ${documentNumber}`);
                setIsCountingDocuments(true);
            } else if (t < 8000) {
                setCurrentPhase('Processing documents...');
                setIsCountingDocuments(false);
            } else if (t < 15000) {
                // Processing counting phase
                if (t >= 8000 && t < 8100) {
                    setDocumentNumber(1);
                }
                setCurrentPhase(`Processing document ${documentNumber}`);
                setIsCountingDocuments(true);
            } else if (t < 17000) {
                setCurrentPhase('Analyzing...');
                setIsCountingDocuments(false);
            } else {
                setCurrentPhase('Generating results...');
                setIsCountingDocuments(false);
            }

            currentTimeRef.current += 100;
            mainIntervalId = window.setTimeout(updateSequence, 100);
        };

        updateSequence();

        return () => {
            clearTimeout(mainIntervalId);
        };
    }, [documentNumber]);

    let displayedPhase = currentPhase;
    if (displayedPhase.includes('...')) {
        const dots = '.'.repeat(dotCount);
        displayedPhase = displayedPhase.replace('...', dots);
    }

    return (
        <div className={styles.loadingContainer}>
            <div className={styles.loadingText}>
                {displayedPhase}
            </div>
        </div>
    );
};

export default LoadingSequence;
