import { create } from "zustand";
import { Topic } from "../models/topic";

export interface ChatState {
  userPrompt: string;
  topicSelected: Topic | null;
  setUserPrompt: (prompt: string) => void;
}

export const useChatStore = create<ChatState>((set) => ({
  userPrompt: "",
  topicSelected: null,
  setUserPrompt: (prompt: string) => set({ userPrompt: prompt }),
  setTopicSelected: (topic: Topic) => set({ topicSelected: topic }),
}));
