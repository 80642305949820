import { TenantSettings } from "../../api";
import styles from "./Layout.module.css";
import { useState } from "react";

export const toggleParentBorder = () => {
    const parentBorder = document.getElementById('parentBorder') as HTMLElement | null;
    if (parentBorder) {
        parentBorder.style.borderBottomRightRadius = "0px";
    }
}
export const toggleParentBorderOff = () => {
    const parentBorder = document.getElementById('parentBorder') as HTMLElement | null;
    if (parentBorder) {
        parentBorder.style.borderBottomRightRadius = "8px";
    }
}

export const NavContainer: React.FC<{ tenantSettings: TenantSettings }> = ({ tenantSettings }) => {
    const [isDropdownActive, setIsDropdownActive] = useState(false);

    const toggleNavDropdown = () => {
        setIsDropdownActive(!isDropdownActive);
    }

    return <>
        {tenantSettings?.visibility.navigation && <div className={styles.headerSelectNav} id="headerSelectNavFixed" >
            <div className={`${styles.headerSelectedNav} ${isDropdownActive ? 'active' : ''} first-class`}
                onClick={() => toggleNavDropdown()}
                id="headerSelectNav"
            >
                {tenantSettings?.navigation.menuItems.filter(menuItem => menuItem.selected)[0]?.label}
                <div className={`${styles.headerSelectedNavDropDown} second-class`} id="parentBorder" style={{ display: "none" }}>
                    <ul className={styles.dialogItems}>
                        <li className={styles.promptClickerTitle}>
                            Select Madison AI Assistant
                        </li>
                        {
                            tenantSettings?.navigation.menuItems.map(menuItem =>
                                menuItem.children && menuItem.children.length > 0
                                    ? <>
                                        <hr />
                                        <li className={styles.reportClicker} onMouseOver={() => toggleParentBorder()} onMouseOut={() => toggleParentBorderOff()}>
                                            <a href={menuItem.url} style={{ display: 'block', width: '100%', padding: '12px 22px 12px 10px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>{menuItem.label}</span>
                                                    <img alt="icon" src="https://54493.fs1.hubspotusercontent-na1.net/hubfs/54493/iGovern/icons/icon-arrow-right-black.svg"></img>
                                                </div>
                                            </a>
                                            <div className={styles.navFlyout} onMouseOver={() => toggleParentBorder()} onMouseOut={() => toggleParentBorderOff()}>
                                                <ul>
                                                    {
                                                        menuItem.children.map(child => <li className={`promptClicker ${child.selected ? 'selected' : 'notSelected'}`}>
                                                            <a href={child.url}>
                                                                <span>{child.label}</span>
                                                                <div className={styles.dialogItemsCheck}>
                                                                    <input type="checkbox" className="checkbox" />
                                                                    <label htmlFor="myCheckbox">
                                                                        <span className={styles.checkboxIcon}></span>
                                                                    </label>
                                                                </div>
                                                            </a>
                                                        </li>)
                                                    }
                                                </ul>

                                            </div>
                                        </li >
                                    </>
                                    :
                                    <li className={`promptClicker ${menuItem.selected ? 'selected' : 'notSelected'}`} key={menuItem.id}>
                                        <a href={menuItem.url}>{menuItem.label}</a>
                                        <div className={styles.dialogItemsCheck}>
                                            <input type="checkbox" className="checkbox" />
                                            <label htmlFor="myCheckbox">
                                                <span className={styles.checkboxIcon}></span>
                                            </label>
                                        </div>
                                        <div className={styles.navDescription}>{menuItem.description}</div>
                                    </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div >}
    </>
}